const VietnamIcon = ({ width = 21, height = 20 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)">
                <rect x="0.333" y="2.858" width="20" height="14.286" rx="2" fill="#fff"></rect>
                <mask
                    id="b"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="2"
                    width="21"
                    height="16"
                    style={{ maskType: 'alpha' }} // React uses camelCase and JS object for styles
                >
                    <rect x="0.333" y="2.858" width="20" height="14.286" rx="2" fill="#fff"></rect>
                </mask>
                <g mask="url(#b)">
                    <path fill="#EA403F" d="M.333 2.858h20v14.286h-20z"></path>
                    <path
                        fillRule="evenodd" // Corrected for React
                        clipRule="evenodd" // Corrected for React
                        d="m10.334 11.673-2.52 1.795.93-2.95-2.486-1.841 3.093-.028.983-2.934.982 2.934 3.093.028-2.486 1.84.93 2.951-2.52-1.795Z"
                        fill="#FFFE4E"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M.333 0h20v20h-20z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

const UserIcon = ({ width = 18, height = 18, className }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4828_4386)">
            <path
                d="M9 9C10.6575 9 12 7.6575 12 6C12 4.3425 10.6575 3 9 3C7.3425 3 6 4.3425 6 6C6 7.6575 7.3425 9 9 9ZM9 10.5C6.9975 10.5 3 11.505 3 13.5V15H15V13.5C15 11.505 11.0025 10.5 9 10.5Z"
                fill="white"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_4828_4386">
                <rect width={width} height={height} fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);

const PlaneIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M74.1208 22.4674C73.2023 20.5935 71.4876 19.2111 69.4669 18.6581L69.2526 18.5967C64.2008 17.2757 58.8428 18.1666 54.5257 21.085H54.4951L16.0705 50.3001C15.3969 50.8224 14.4477 50.6995 13.8966 50.0544L11.0186 46.6444L10.988 46.6137C9.91636 45.5078 8.29371 45.2313 6.91593 45.9071C5.20137 46.7673 4.49717 48.887 5.38507 50.6073L9.15093 58.1031C10.6512 61.083 14.0498 62.5268 17.1727 61.6974C17.6014 61.5745 17.9994 61.4209 18.3974 61.2366L62.823 39.5787C68.0585 37.674 72.2531 33.6189 74.3657 28.4579C75.1311 26.5532 75.0393 24.3721 74.1208 22.4674Z"
            fill="#14C560"
        />
        <path
            d="M73.0801 20.9314L72.5903 20.3784L34.9617 43.9717L9.97803 59.6391L10.9271 60.4071C12.6723 61.851 14.9992 62.3118 17.1424 61.7588C17.5711 61.636 17.9691 61.4824 18.3671 61.298L62.7928 39.6402C68.0283 37.7355 72.2229 33.6803 74.3355 28.5193C75.3764 25.9388 74.9172 23.0204 73.0801 20.9314Z"
            fill="#028090"
        />
        <path
            d="M53.6377 32.6359L12.1819 30.7312C10.6511 30.6698 9.15079 31.2535 8.07919 32.3287C7.00759 33.4346 6.4259 34.9399 6.51775 36.4759C6.54837 36.9675 6.88515 37.3975 7.3444 37.5204L35.0837 45.7842C35.298 45.8457 35.5123 45.8457 35.7266 45.7842C35.8184 45.7535 35.9409 45.7227 36.0327 45.6613L54.1888 34.7556C54.6174 34.4791 54.8317 33.9568 54.7093 33.4653C54.5868 33.0352 54.1582 32.6666 53.6377 32.6359Z"
            fill="#FF8E72"
        />
        <path
            d="M73.0184 20.8699C72.0693 19.7947 70.8446 19.0574 69.4362 18.6888L69.2219 18.6273C65.609 17.675 61.8431 17.8593 58.3528 19.1496C58.1078 19.2417 57.8934 19.4568 57.8322 19.7333C57.7404 20.0098 57.8016 20.2863 57.9547 20.5013L57.9853 20.532L62.9759 27.0448C63.1902 27.3213 63.5576 27.4441 63.8944 27.352C63.9557 27.3213 64.0475 27.2905 64.1087 27.2598L72.8041 22.1909C73.0184 22.068 73.1715 21.8223 73.2327 21.5765C73.2633 21.3308 73.2021 21.0543 73.0184 20.8699Z"
            fill="#1BE7FF"
        />
        <path
            d="M45.861 61.9739C45.4629 62.066 45.0649 61.9124 44.8506 61.5437C44.6363 61.1137 44.7893 60.5915 45.218 60.3764L55.934 54.7546C56.3626 54.5395 56.8832 54.6931 57.0975 55.1232C57.3118 55.5533 57.1587 56.0755 56.7301 56.2906L46.014 61.9124C45.9834 61.9124 45.9222 61.9431 45.861 61.9739Z"
            fill="#1E3888"
        />
        <path
            d="M36.4313 60.9601C36.0332 61.0523 35.6352 60.8987 35.4209 60.5301C35.2066 60.1 35.3596 59.5777 35.7883 59.3627L52.8727 50.3923C53.3014 50.1773 53.8219 50.3309 54.0362 50.761C54.2505 51.1911 54.0974 51.7133 53.6688 51.9284L36.5844 60.8987C36.5537 60.9294 36.4925 60.9294 36.4313 60.9601Z"
            fill="#1E3888"
        />
        <path
            d="M45.9228 39.7323L7.83494 35.2778C7.22259 35.2164 6.67142 35.6158 6.54895 36.1994C6.42648 36.7831 6.7633 37.3669 7.34503 37.5512L35.0843 45.8149C35.2986 45.8764 35.5129 45.8764 35.7272 45.8149C35.8191 45.7842 35.9415 45.7535 36.0334 45.6921L45.9228 39.7323Z"
            fill="#028090"
        />
    </svg>
);

const AccidentIcon = ({ className, width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.5906 41.8117L19.7872 33.0057C16.1224 29.3397 16.1224 23.3305 19.7872 19.6645C23.3388 16.1118 29.5729 16.1118 33.1245 19.6645L41.9276 28.4705L28.5906 41.8117Z"
            fill="#1BE7FF"
        />
        <path
            d="M46.9147 60.2174L38.1113 51.3358L51.4486 37.9946L60.2517 46.914C63.9166 50.58 63.9166 56.5892 60.2517 60.2552C56.5869 63.9212 50.6173 63.9212 46.9147 60.2174Z"
            fill="#FF8E72"
        />
        <path
            d="M60.2513 19.6645C56.6997 16.1118 50.4657 16.1118 46.9142 19.6645L40 26.5808L19.7487 46.876C16.0838 50.542 16.0838 56.5513 19.7487 60.2173C23.4135 63.9211 29.3832 63.9211 33.0859 60.2173L40 53.301L60.2513 33.0057C63.9161 29.3019 63.9161 23.3305 60.2513 19.6645Z"
            fill="#028090"
        />
        <path
            d="M60.2513 33.0057L40 53.301V26.5808L46.9143 19.6645C50.4658 16.1118 56.6998 16.1118 60.2513 19.6645C63.9162 23.3305 63.9162 29.3019 60.2513 33.0057Z"
            fill="#14C560"
        />
        <path d="M38.1114 47.5187L32.4062 41.8118L41.9274 32.2877L47.6326 37.9945L38.1114 47.5187Z" fill="#FFE388" />
        <path d="M49.7866 24.4269L51.7136 22.5372L53.6027 24.4647L51.6757 26.3544L49.7866 24.4269Z" fill="#FFC53A" />
        <path d="M53.564 28.2437L55.4531 26.354L57.3422 28.2437L55.4531 30.1334L53.564 28.2437Z" fill="#FFC53A" />
        <path d="M45.9702 28.2437L47.8593 26.354L49.7484 28.2437L47.8593 30.1334L45.9702 28.2437Z" fill="#FFC53A" />
        <path d="M49.7866 32.0233L51.7136 30.1336L53.6027 32.061L51.6757 33.9507L49.7866 32.0233Z" fill="#FFC53A" />
        <path d="M26.436 47.8585L28.363 45.9688L30.2521 47.8963L28.3251 49.786L26.436 47.8585Z" fill="#FFE388" />
        <path d="M30.252 51.6382L32.1411 49.7485L34.0302 51.6382L32.1411 53.5279L30.252 51.6382Z" fill="#FFE388" />
        <path d="M22.6196 51.6382L24.5087 49.7485L26.3979 51.6382L24.5087 53.5279L22.6196 51.6382Z" fill="#FFE388" />
        <path d="M26.4746 55.493L28.4015 53.6033L30.2906 55.5308L28.3637 57.4205L26.4746 55.493Z" fill="#FFE388" />
        <path d="M26.436 24.4267L28.3251 22.4992L30.2521 24.3889L28.363 26.3164L26.436 24.4267Z" fill="#B0E6FB" />
        <path d="M22.6196 28.2437L24.5087 26.354L26.3979 28.2437L24.5087 30.1334L22.6196 28.2437Z" fill="#B0E6FB" />
        <path d="M30.252 28.2437L32.1411 26.354L34.0302 28.2437L32.1411 30.1334L30.252 28.2437Z" fill="#B0E6FB" />
        <path d="M26.436 32.061L28.3251 30.1336L30.2521 32.0233L28.363 33.9507L26.436 32.061Z" fill="#B0E6FB" />
        <path d="M49.7866 47.8587L51.6757 45.9312L53.6027 47.8209L51.7136 49.7484L49.7866 47.8587Z" fill="#1BE7FF" />
        <path d="M45.9702 51.6762L47.8593 49.7865L49.7484 51.6762L47.8593 53.5659L45.9702 51.6762Z" fill="#1BE7FF" />
        <path d="M53.564 51.6762L55.4531 49.7865L57.3422 51.6762L55.4531 53.5659L53.564 51.6762Z" fill="#1BE7FF" />
        <path d="M49.7866 55.4552L51.6757 53.5276L53.6027 55.4173L51.7136 57.3448L49.7866 55.4552Z" fill="#1BE7FF" />
        <path d="M40 45.629L47.6321 37.9945L41.9269 32.2877L40.0378 34.1774V45.629H40Z" fill="#FFC53A" />
    </svg>
);

const BikeIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M57.9026 25.77C58.8388 25.77 59.5977 24.1977 59.5977 22.258C59.5977 20.3184 58.8388 18.746 57.9026 18.746C56.9664 18.746 56.2075 20.3184 56.2075 22.258C56.2075 24.1977 56.9664 25.77 57.9026 25.77Z"
            fill="#FFC53A"
        />
        <path
            d="M42.8619 20.4864H50.4437C50.7519 20.4864 50.9984 20.7351 50.9984 21.0459V23.5013C50.9984 23.8121 50.7519 24.0606 50.4437 24.0606H42.8619C42.5537 24.0606 42.3071 23.8121 42.3071 23.5013V21.0459C42.3071 20.7351 42.5537 20.4864 42.8619 20.4864Z"
            fill="#1E3888"
        />
        <path
            d="M57.9021 26.0187V18.777C57.9021 18.1243 57.3165 18 57.3165 18H53.7414C51.4299 18 49.519 19.8648 49.4882 22.1958C49.4266 24.6201 51.4299 26.5782 53.8339 26.5782H57.3165C57.8096 26.5782 57.9021 26.2052 57.9021 26.0187Z"
            fill="#1BE7FF"
        />
        <path
            d="M60.0603 62.1339C63.3965 62.1339 66.101 59.4066 66.101 56.0422C66.101 52.6779 63.3965 49.9505 60.0603 49.9505C56.7241 49.9505 54.0195 52.6779 54.0195 56.0422C54.0195 59.4066 56.7241 62.1339 60.0603 62.1339Z"
            fill="#B0E6FB"
        />
        <path
            d="M60.0592 63.1285C55.7444 63.1285 52.2617 59.5854 52.2617 55.2652C52.2617 50.9451 55.7752 47.4019 60.0592 47.4019C64.3432 47.4019 67.8568 50.9451 67.8568 55.2652C67.8568 59.5854 64.3432 63.1285 60.0592 63.1285ZM60.0592 50.945C57.6861 50.945 55.7752 52.872 55.7752 55.2652C55.7752 57.6584 57.6861 59.5853 60.0592 59.5853C62.4324 59.5853 64.3432 57.6584 64.3432 55.2652C64.3432 52.872 62.4016 50.945 60.0592 50.945Z"
            fill="#1E3888"
        />
        <path
            d="M28.2849 61.6688C31.3671 60.3813 32.8309 56.8179 31.5542 53.7096C30.2774 50.6014 26.7438 49.1253 23.6615 50.4128C20.5792 51.7003 19.1156 55.2638 20.3923 58.372C21.669 61.4803 25.2026 62.9563 28.2849 61.6688Z"
            fill="#B0E6FB"
        />
        <path
            d="M25.9728 47.4019C21.658 47.4019 18.1753 50.9451 18.1753 55.2652C18.1753 59.5854 21.6888 63.1285 25.9728 63.1285C30.2568 63.1285 33.7703 59.5854 33.7703 55.2652C33.7703 50.9451 30.2568 47.4019 25.9728 47.4019ZM25.9728 59.5853C23.5996 59.5853 21.6888 57.6584 21.6888 55.2652C21.6888 52.872 23.5996 50.945 25.9728 50.945C28.3459 50.945 30.2568 52.872 30.2568 55.2652C30.2568 57.6584 28.3151 59.5853 25.9728 59.5853Z"
            fill="#1E3888"
        />
        <path
            d="M41.7219 46.7803H40.1193C37.3763 46.7803 33.4005 47.0289 35.1572 41.7764L36.6057 36.6481L18.2061 37.0833C17.0966 38.3887 16.1412 39.7873 15.3707 41.1859C14.8159 42.1804 14.2303 43.3926 13.6447 44.7912L13.9529 47.1222L12.3811 48.3032C12.3194 48.5208 12.2578 48.7073 12.1962 48.9248C11.8263 50.4478 11.4257 54.2707 16.6035 54.2707H41.7527L43.2629 50.0437L41.7219 46.7803Z"
            fill="#14C560"
        />
        <path
            d="M18.9764 44.7601H13.6445C13.0897 46.0966 12.6582 47.3087 12.3809 48.3032H18.9764C19.5312 48.3032 19.9935 47.8371 19.9935 47.2776V45.8168C19.9935 45.2263 19.5312 44.7601 18.9764 44.7601Z"
            fill="#FFC53A"
        />
        <path
            d="M52.3248 24.1539L49.4893 22.2891V41.0616C49.4893 41.0616 49.7667 46.8114 43.7876 46.8114H40.459V54.3017H46.9005C48.4415 54.3017 49.2736 53.4315 49.2736 53.4315L56.8862 43.579V32.1105C56.917 28.7849 55.1602 25.7701 52.3248 24.1539Z"
            fill="#028090"
        />
        <path
            d="M36.8831 33.5712H23.9386C21.5346 33.5712 18.2368 37.1143 18.2368 37.1143H36.8831C37.8385 37.1143 38.6398 36.3063 38.6398 35.3428C38.6398 34.3793 37.8385 33.5712 36.8831 33.5712Z"
            fill="#FF8E72"
        />
        <path
            d="M65.3294 46.5938C65.2369 46.5006 65.1445 46.3763 65.052 46.283C62.0933 43.175 57.2854 42.4912 53.5253 44.8844C49.5803 47.4019 48.1934 52.468 50.1042 56.6017C50.32 57.0058 50.7822 57.5652 51.4603 57.1301L64.7438 48.6763C66.0074 47.8992 65.8225 47.06 65.3294 46.5938Z"
            fill="#FF8E72"
        />
    </svg>
);

const CarComprehensiveIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.24817 28.5991H12.7912C14.0271 28.5991 15.0383 29.6103 15.0383 30.8462V43.9172C15.0383 45.1532 14.0271 46.1644 12.7912 46.1644H7.24817C6.01223 46.1644 5.00101 45.1532 5.00101 43.9172V30.8462C4.96356 29.6103 5.97478 28.5991 7.24817 28.5991Z"
            fill="#273C86"
        />
        <path
            d="M41.7422 18H29.7573L12.604 35.0035V52.1194C12.604 53.6924 13.8774 54.9283 15.413 54.9283H19.8324H41.7047L43.9144 40.6588L41.7422 18Z"
            fill="#41B760"
        />
        <path
            d="M69.9435 35.0035H61.1047L41.7417 29.2732V54.9657H63.9137H72.1907C73.7263 54.9657 74.9996 53.7298 74.9996 52.1568V45.4902L72.0783 43.318L74.9996 41.333V40.0596C74.9996 37.2507 72.715 35.0035 69.9435 35.0035Z"
            fill="#098091"
        />
        <path
            d="M51.367 18H41.7417V35.0035H61.1047L54.6629 20.1723C54.0636 18.824 52.7902 18 51.367 18Z"
            fill="#6BCCE0"
        />
        <path d="M15.2631 18H29.7573V35.0035H12.604V20.6217C12.604 19.161 13.8025 18 15.2631 18Z" fill="#F68E75" />
        <path
            d="M68.8205 41.333H75.0002V45.4903H68.8205C67.6595 45.4903 66.7231 44.5539 66.7231 43.3929C66.7231 42.2693 67.6595 41.333 68.8205 41.333Z"
            fill="#FFC53C"
        />
        <path
            d="M27.3604 61.3702C30.8435 61.3702 33.6524 58.5612 33.6524 55.0781C33.6524 51.595 30.8435 48.7861 27.3604 48.7861C23.8773 48.7861 21.0684 51.595 21.0684 55.0781C21.0684 58.5612 23.8773 61.3702 27.3604 61.3702Z"
            fill="#B3E3F7"
        />
        <path
            d="M27.3601 62.4188C22.8658 62.4188 19.2329 58.7485 19.2329 54.2916C19.2329 49.8347 22.9033 46.1644 27.3601 46.1644C31.817 46.1644 35.4874 49.8347 35.4874 54.2916C35.5248 58.7485 31.8544 62.4188 27.3601 62.4188ZM27.3601 49.7973C24.8883 49.7973 22.9033 51.7823 22.9033 54.2542C22.9033 56.726 24.8883 58.711 27.3601 58.711C29.832 58.711 31.817 56.726 31.817 54.2542C31.8545 51.7823 29.832 49.7973 27.3601 49.7973Z"
            fill="#273C86"
        />
        <path
            d="M56.087 61.3702C59.5701 61.3702 62.379 58.5612 62.379 55.0781C62.379 51.595 59.5701 48.7861 56.087 48.7861C52.6039 48.7861 49.7949 51.595 49.7949 55.0781C49.7949 58.5612 52.6039 61.3702 56.087 61.3702Z"
            fill="#B3E3F7"
        />
        <path
            d="M56.0867 62.4188C51.5924 62.4188 47.9595 58.7485 47.9595 54.2916C47.9595 49.8347 51.6298 46.1644 56.0867 46.1644C60.5436 46.1644 64.2139 49.8347 64.2139 54.2916C64.2514 58.7485 60.581 62.4188 56.0867 62.4188ZM56.0867 49.7973C53.6148 49.7973 51.6298 51.7823 51.6298 54.2542C51.6298 56.726 53.6148 58.711 56.0867 58.711C58.5586 58.711 60.5436 56.726 60.5436 54.2542C60.581 51.7823 58.5586 49.7973 56.0867 49.7973Z"
            fill="#273C86"
        />
        <path
            d="M47.6971 38.8237H44.214C43.8021 38.8237 43.5024 38.4866 43.5024 38.1121C43.5024 37.7001 43.8395 37.4005 44.214 37.4005H47.6971C48.1091 37.4005 48.4087 37.7376 48.4087 38.1121C48.3713 38.5241 48.0717 38.8237 47.6971 38.8237Z"
            fill="#273C86"
        />
    </svg>
);

const CarIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.3433 38.1467C6.51672 38.1467 5.02734 39.6523 5.02734 41.4987V52.7763C5.02734 54.6227 6.51672 56.1568 8.3433 56.1568H39.086C40.0696 56.1568 40.9408 55.4466 41.1656 54.4807L42.6549 47.7483C42.7111 47.4358 42.7111 47.1233 42.6549 46.8108L41.1374 39.8228C40.9126 38.8285 40.0696 38.1467 39.058 38.1467H8.3433Z"
            fill="#14C560"
        />
        <path
            d="M5 44.5667H10.1707C10.8451 44.5667 11.379 45.1064 11.379 45.7882V47.2086C11.379 47.8903 10.8451 48.43 10.1707 48.43H5V44.5667Z"
            fill="#FFC53A"
        />
        <path
            d="M29.6453 24H22.2266C18.9668 24 16.0443 26.0737 14.9484 29.1985L11.8291 38.1467H38.5253L39.537 31.6699L38.5253 24H29.6453Z"
            fill="#FF8E72"
        />
        <path
            d="M66.682 48.43C66.3448 48.43 66.0919 48.146 66.0919 47.8335V45.1916C66.0919 44.8507 66.3729 44.5951 66.682 44.5951H73.2858C71.8245 42.0668 69.6326 40.4476 67.8342 39.4818C66.9068 39.0557 64.6587 38.2603 60.2187 38.1467H38.103V56.1568H71.3187C73.342 56.1568 75 54.4807 75 52.4354V51.2991C75 50.2481 74.8876 49.2539 74.719 48.3448C74.6347 48.4016 74.5222 48.43 74.4098 48.43H66.682Z"
            fill="#028090"
        />
        <path
            d="M66.6819 44.5666C66.3447 44.5666 66.0918 44.8507 66.0918 45.1631V47.805C66.0918 48.1459 66.3728 48.4016 66.6819 48.4016H74.3817C74.4941 48.4016 74.6064 48.3732 74.6907 48.3164C74.4097 46.8676 73.9039 45.6177 73.2576 44.5382H66.6819V44.5666Z"
            fill="#FFC53A"
        />
        <path
            d="M21.1857 62.8892C17.1672 62.8892 13.8794 59.594 13.8794 55.5318C13.8794 51.4696 17.1391 48.1744 21.1857 48.1744C25.2323 48.1744 28.492 51.4696 28.492 55.5318C28.4639 59.5656 25.2042 62.8892 21.1857 62.8892ZM21.1857 52.5775C19.5839 52.5775 18.2632 53.9126 18.2632 55.5318C18.2632 57.151 19.5839 58.4861 21.1857 58.4861C22.7875 58.4861 24.1082 57.151 24.1082 55.5318C24.1082 53.9126 22.7875 52.5775 21.1857 52.5775Z"
            fill="#1E3888"
        />
        <path
            d="M25.3453 55.5034C25.3453 57.8328 23.4906 59.7076 21.1863 59.7076C18.882 59.7076 17.0273 57.8328 17.0273 55.5034C17.0273 53.174 18.882 51.2991 21.1863 51.2991C23.4906 51.2991 25.3453 53.2024 25.3453 55.5034Z"
            fill="#B0E6FB"
        />
        <path
            d="M55.9196 62.8892C51.9011 62.8892 48.6133 59.594 48.6133 55.5318C48.6133 51.4696 51.873 48.1744 55.9196 48.1744C59.9662 48.1744 63.2259 51.4696 63.2259 55.5318C63.1978 59.5656 59.9381 62.8892 55.9196 62.8892ZM55.9196 52.5775C54.3178 52.5775 52.997 53.9126 52.997 55.5318C52.997 57.151 54.3178 58.4861 55.9196 58.4861C57.5214 58.4861 58.8421 57.151 58.8421 55.5318C58.8421 53.9126 57.5214 52.5775 55.9196 52.5775Z"
            fill="#1E3888"
        />
        <path
            d="M59.9657 55.5034C59.9657 57.776 58.1392 59.594 55.9192 59.594C53.6992 59.594 51.8726 57.7476 51.8726 55.5034C51.8726 53.2592 53.6992 51.4128 55.9192 51.4128C58.1673 51.4128 59.9657 53.2592 59.9657 55.5034Z"
            fill="#B0E6FB"
        />
        <path
            d="M46.1683 28.0338C43.6111 24.4262 40.8291 24.0284 40.0142 24H38.0752V38.1467H53.3341L46.1683 28.0338Z"
            fill="#1BE7FF"
        />
        <path
            d="M44.9876 42.3794H41.3344C40.9129 42.3794 40.5757 42.0384 40.5757 41.6123C40.5757 41.1862 40.9129 40.8454 41.3344 40.8454H44.9876C45.4091 40.8454 45.7463 41.1862 45.7463 41.6123C45.7182 42.0384 45.381 42.3794 44.9876 42.3794Z"
            fill="#1E3888"
        />
    </svg>
);

const HealthCancerIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M40.3365 65.4223C34.2442 59.3261 34.2483 49.4453 40.3447 43.3532H40.3461C43.3957 40.3024 47.3921 38.779 51.3885 38.7817C51.4294 38.7817 51.4691 38.7817 51.51 38.7831C51.5948 38.7831 51.6781 38.7831 51.7628 38.7858C51.8749 38.7886 51.9869 38.7927 52.0976 38.7981C52.1098 38.7981 52.1208 38.7981 52.1331 38.7981C52.7247 38.8254 53.3067 38.8883 53.8792 38.9798C48.9387 28.4761 31.1605 21.168 31.1605 21.168C30.8709 21.2814 30.5853 21.4002 30.3025 21.5245C28.5045 22.3142 26.4796 22.4563 24.5914 21.9221C23.1035 21.5013 21.4162 21.8197 20.2261 22.9263C18.9035 24.1218 18.4704 25.9075 18.8899 27.4951C19.3995 29.4242 19.3326 31.4682 18.5292 33.2935C18.4035 33.579 18.2833 33.8673 18.1698 34.1597C17.4443 36.011 16.0794 37.5384 14.3552 38.529C13.0285 39.29 12.0817 40.6958 12.0256 42.3026C11.9355 44.1224 12.9383 45.7196 14.4112 46.5284C16.1395 47.4779 17.4512 49.0218 18.1698 50.8581C18.2833 51.1463 18.4008 51.4319 18.5251 51.7133C19.3189 53.5195 19.4419 55.5511 18.9145 57.453C18.5073 58.9231 18.8188 60.5831 19.9023 61.7662C21.0992 63.1106 22.8986 63.5506 24.4971 63.1284C26.4318 62.6174 28.4826 62.6926 30.3162 63.4959C30.5949 63.6189 30.8777 63.735 31.1619 63.847C33.0132 64.5725 34.5407 65.9388 35.5313 67.663C35.7212 67.9936 35.9521 68.3024 36.2158 68.5784C36.2595 68.6248 36.3169 68.6658 36.3825 68.7055C36.3743 68.7109 36.3661 68.7164 36.3579 68.7205C37.0383 69.3749 37.9155 69.8272 38.8855 69.9569C39.8624 68.8503 40.8052 67.7286 41.696 66.6328C41.226 66.2599 40.7724 65.8582 40.3379 65.4237L40.3365 65.4223Z"
            fill="#41B760"
        />
        <path
            d="M43.4094 67.8147C42.8178 67.4636 42.2454 67.0687 41.6947 66.6315C40.8039 67.7286 39.8612 68.8489 38.8843 69.9556C39.0223 69.9734 39.1616 69.9857 39.3024 69.9911C41.0362 70.0786 42.5678 69.1714 43.4094 67.8133V67.8147Z"
            fill="#098091"
        />
        <path
            d="M53.8802 38.9798C58.5215 39.7245 62.4769 42.5143 64.7968 46.3959C66.1167 45.5543 66.9938 44.0295 66.9966 42.3012C67.0007 40.5538 66.0306 39.1178 64.7203 38.3883C62.9729 37.4127 61.556 35.929 60.8182 34.0695C60.7157 33.8113 60.6092 33.5544 60.4971 33.3017C59.7047 31.4982 59.5749 29.4707 60.105 27.5743C60.5176 26.0974 60.2034 24.4265 59.1103 23.2419C57.9162 21.9085 56.1264 21.4699 54.5346 21.888C52.5972 22.3962 50.5451 22.3266 48.7102 21.5205C48.4301 21.3975 48.1472 21.28 47.8617 21.168C46.009 20.4425 44.4815 19.0735 43.4896 17.3493C42.7285 16.0267 41.3254 15.084 39.7227 15.0253C38.4111 14.9583 37.2142 15.4611 36.3439 16.2959C36.005 16.6211 35.7167 16.9968 35.4899 17.4108C34.5404 19.1391 32.9964 20.448 31.1602 21.1666C31.1602 21.1666 48.9383 28.4747 53.8802 38.9798Z"
            fill="#098091"
        />
        <path
            d="M60.8541 50.8581C60.7407 51.1463 60.6232 51.4319 60.4988 51.7147C59.6941 53.5455 59.6258 55.5921 60.1354 57.5254C60.5548 59.1143 60.119 60.9028 58.791 62.0982C58.295 62.5573 57.7116 62.8784 57.094 63.0683L54.6074 64.8321L53.2001 62.847C51.8816 62.6803 50.5386 62.8224 49.2816 63.2691L48.1749 64.8308L47.198 64.1381C45.6555 64.8936 44.3657 66.0877 43.535 67.6015C43.4954 67.6726 43.453 67.7423 43.4106 67.8119C45.8618 69.2697 48.6271 69.9966 51.3911 69.9952C51.454 69.9952 51.5182 69.9938 51.5811 69.9925C51.6384 69.9925 51.6972 69.9925 51.7546 69.9897C60.2078 69.7971 66.9996 62.8839 66.9996 54.3857C66.9996 51.4647 66.1963 48.7308 64.7999 46.3931C64.7275 46.4396 64.6565 46.4847 64.5813 46.5257C62.8693 47.4861 61.5714 49.0273 60.8554 50.854L60.8541 50.8581Z"
            fill="#63CBE7"
        />
        <path
            d="M53.0918 50.4755C53.0972 50.1107 52.9756 49.8142 52.895 49.6585C52.7516 49.3839 52.3212 48.7417 51.388 48.7417C50.4548 48.7417 50.0244 49.3825 49.881 49.6585C49.7389 49.9345 49.4656 50.6572 50.0053 51.4169L51.388 53.3679L52.7721 51.4169C53.0071 51.0849 53.0877 50.7597 53.0918 50.4755Z"
            fill="#F68E75"
        />
        <path
            d="M48.9331 56.8313L46.7375 53.7354C45.4996 51.988 45.3411 49.7214 46.3235 47.8195C47.3072 45.9177 49.2487 44.7359 51.3897 44.7359C52.7382 44.7359 54.0061 45.2045 55.0062 46.0256C55.3956 43.5595 54.9256 41.1986 53.8818 38.9798C53.3093 38.8883 52.7273 38.8255 52.1357 38.7981C52.1234 38.7981 52.1124 38.7981 52.1001 38.7981C51.9881 38.7927 51.8774 38.7886 51.7654 38.7858C51.6807 38.7831 51.5973 38.7831 51.5126 38.7831C51.4716 38.7831 51.432 38.7817 51.391 38.7817C47.3946 38.7804 43.3982 40.3024 40.3487 43.3519H40.3473C34.2509 49.4454 34.2468 59.3275 40.3391 65.4237C40.7736 65.8582 41.2272 66.2599 41.6972 66.6329C42.9023 65.1491 44.0103 63.7104 44.9804 62.4139L48.9358 56.8327L48.9331 56.8313Z"
            fill="#F68E75"
        />
        <path
            d="M49.2803 63.2705C50.5373 62.8237 51.8803 62.6803 53.1988 62.8483L51.3898 60.2962L49.2816 63.2705H49.2803Z"
            fill="#63CBE7"
        />
        <path d="M51.3887 53.3679L52.7727 51.4169L51.3887 53.3693V53.3679Z" fill="#63CBE7" />
        <path
            d="M47.1964 64.1394L44.9051 62.5136L44.9775 62.4111C44.0088 63.7077 42.8994 65.1464 41.6943 66.6301C42.245 67.066 42.8174 67.4608 43.409 67.8133C43.4514 67.7436 43.4951 67.6753 43.5334 67.6029C44.3641 66.0877 45.6538 64.895 47.1964 64.1394Z"
            fill="#63CBE7"
        />
        <path
            d="M53.8802 38.9798C54.924 41.1986 55.3954 43.5609 55.0046 46.0256C55.5935 46.5093 56.0894 47.1145 56.4529 47.8182C56.8833 48.6502 57.095 49.5519 57.0923 50.4509C57.0896 51.6054 56.7357 52.7531 56.0389 53.7354L53.8433 56.8313L57.8725 62.5136L57.0909 63.0683C57.7085 62.8784 58.2919 62.5573 58.7879 62.0983C60.1159 60.9028 60.5517 59.1157 60.1323 57.5254C59.6227 55.5921 59.691 53.5441 60.4957 51.7147C60.6201 51.4319 60.7389 51.1464 60.851 50.8581C61.5669 49.0314 62.8649 47.4903 64.5768 46.5298C64.6506 46.4874 64.723 46.4423 64.7954 46.3972C62.4768 42.5157 58.5201 39.7258 53.8788 38.9812L53.8802 38.9798Z"
            fill="#63CBE7"
        />
        <path d="M44.9775 62.4125C47.3877 59.1854 48.9329 56.8314 48.9329 56.8314L44.9775 62.4125Z" fill="#273C86" />
        <path
            d="M51.3882 44.7359C49.2473 44.7359 47.3071 45.9177 46.322 47.8195C45.3397 49.7213 45.4982 51.9893 46.736 53.7354L48.9316 56.8313L51.3869 53.3679L50.0042 51.4169C49.4645 50.6572 49.7378 49.9345 49.8798 49.6585C50.0233 49.3839 50.4551 48.7417 51.3869 48.7417C52.3187 48.7417 52.7504 49.3825 52.8939 49.6585C52.9745 49.8142 53.0961 50.1107 53.0906 50.4755C53.0865 50.7597 53.0059 51.0849 52.7709 51.4169C54.0183 49.5765 54.7274 47.7758 55.0034 46.0256C54.0033 45.2045 52.7354 44.7345 51.3869 44.7345L51.3882 44.7359Z"
            fill="#273C86"
        />
        <path
            d="M57.8737 62.5136L53.8445 56.8313L56.0401 53.7354C56.737 52.7531 57.0922 51.6054 57.0936 50.4509C57.0949 49.5533 56.8845 48.6516 56.4541 47.8181C56.0893 47.1132 55.5934 46.5079 55.0059 46.0256C54.7299 47.7744 54.0194 49.5765 52.7733 51.4169L51.3893 53.3679L48.9341 56.8313C48.9341 56.8313 47.3888 59.184 44.9787 62.4125L44.9062 62.515L47.1975 64.1408L48.1744 64.8335L49.2811 63.2719L51.3893 60.2975L53.1983 62.8497L54.6055 64.8349L57.0922 63.071L57.8737 62.5163V62.5136Z"
            fill="#273C86"
        />
        <path
            d="M53.2388 17.0037C53.2496 15.9187 52.3788 15.0303 51.2938 15.0196C50.2087 15.0088 49.3204 15.8796 49.3096 16.9646C49.2988 18.0496 50.1696 18.9379 51.2547 18.9487C52.3397 18.9595 53.228 18.0887 53.2388 17.0037Z"
            fill="#F68E75"
        />
        <path
            d="M25.7678 69.9966C26.8537 69.9966 27.7339 69.1164 27.7339 68.0305C27.7339 66.9447 26.8537 66.0645 25.7678 66.0645C24.682 66.0645 23.8018 66.9447 23.8018 68.0305C23.8018 69.1164 24.682 69.9966 25.7678 69.9966Z"
            fill="#F68E75"
        />
        <path
            d="M15.9488 28.7924C15.9596 27.7074 15.0888 26.819 14.0037 26.8083C12.9187 26.7975 12.0303 27.6683 12.0195 28.7533C12.0088 29.8383 12.8796 30.7266 13.9646 30.7374C15.0497 30.7482 15.938 29.8774 15.9488 28.7924Z"
            fill="#63CBE7"
        />
        <path
            d="M31.9537 40.0238C35.3494 40.0184 38.0977 37.2615 38.0924 33.866C38.087 30.4704 35.33 27.7221 31.9344 27.7275C28.5388 27.7328 25.7905 30.4898 25.7958 33.8853C25.8011 37.2808 28.5581 40.0291 31.9537 40.0238Z"
            fill="#FFC53C"
        />
        <path
            d="M31.3372 52.2648C31.3426 50.6244 30.0172 49.2902 28.3767 49.2848C26.7363 49.2793 25.402 50.6047 25.3966 52.2451C25.3911 53.8855 26.7166 55.2198 28.357 55.2252C29.9975 55.2307 31.3317 53.9052 31.3372 52.2648Z"
            fill="#FFC53C"
        />
        <path
            d="M48.67 32.4878C49.4837 31.6741 49.4837 30.3549 48.67 29.5412C47.8563 28.7275 46.537 28.7275 45.7233 29.5412C44.9096 30.3549 44.9096 31.6741 45.7233 32.4878C46.537 33.3014 47.8563 33.3014 48.67 32.4878Z"
            fill="#FFC53C"
        />
        <path
            d="M25.8047 44.1505C25.8116 43.0186 24.8996 42.0955 23.7678 42.0886C22.6359 42.0817 21.7128 42.9936 21.7059 44.1254C21.699 45.2572 22.6109 46.1804 23.7428 46.1873C24.8746 46.1942 25.7978 45.2823 25.8047 44.1505Z"
            fill="#FFC53C"
        />
    </svg>
);

const HealthIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.3225 14C21.1913 14 20.2686 14.9041 20.2686 16.0123V19.4828C20.2686 20.5911 21.1913 21.4952 22.3225 21.4952C23.4537 21.4952 24.3765 20.5911 24.3765 19.4828V16.0123C24.3765 14.8749 23.4537 14 22.3225 14Z"
            fill="#FFC53A"
        />
        <path
            d="M43.0705 17.4998C42.2966 16.7415 41.2547 16.2748 40.0938 16.2748V19.0746C40.4807 19.0746 40.8082 19.2204 41.0463 19.4538C41.2844 19.6871 41.4333 20.0079 41.4333 20.387V29.7488H44.2909V20.387C44.3207 19.2788 43.8445 18.258 43.0705 17.4998Z"
            fill="#1BE7FF"
        />
        <path
            d="M17.3216 17.4997C16.5476 18.258 16.0713 19.2788 16.0713 20.4162V29.7779H18.929V20.4162C18.929 20.037 19.0779 19.7162 19.316 19.4829C19.5541 19.2495 19.8816 19.1038 20.2686 19.1038V16.304C19.1374 16.2748 18.0955 16.7415 17.3216 17.4997Z"
            fill="#1BE7FF"
        />
        <path
            d="M38.1585 14C37.0273 14 36.1045 14.9041 36.1045 16.0123V19.4828C36.1045 20.5911 37.0273 21.4952 38.1585 21.4952C39.2897 21.4952 40.2124 20.5911 40.2124 19.4828V16.0123C40.2124 14.8749 39.2897 14 38.1585 14Z"
            fill="#FFC53A"
        />
        <path
            d="M54.3537 37.8564C52.2104 37.8564 49.2337 38.323 47.2988 40.4812C46.1379 41.8228 45.8997 43.1644 45.8997 43.2227L45.8699 43.3393V55.3842C45.8699 57.1633 45.1555 58.8548 43.8457 60.1088C42.5359 61.3921 40.8392 62.092 39.0234 62.092C37.2076 62.092 35.481 61.3921 34.201 60.1088C32.8913 58.8256 32.1768 57.1633 32.1768 55.3842V48.3265C32.1768 48.0348 31.9387 47.7723 31.6113 47.7723H28.7535C28.4559 47.7723 28.188 48.0057 28.188 48.3265V55.3842C28.188 58.2132 29.3191 60.8671 31.3731 62.8795C33.4271 64.8918 36.1359 66 39.0234 66C41.9108 66 44.6197 64.8918 46.6736 62.8795C48.7276 60.8671 49.8588 58.2132 49.8588 55.3842V43.7477C50.0076 43.3102 50.7816 41.7353 54.3537 41.7353C54.6514 41.7353 54.9193 41.502 54.9193 41.1812V38.3814C54.9193 38.0897 54.6514 37.8564 54.3537 37.8564Z"
            fill="#FF8E72"
        />
        <path
            d="M63.1041 35.4649C63.0743 35.4649 63.1041 35.4649 63.1041 35.4649C61.9134 34.2983 60.3357 33.6567 58.6985 33.6567C57.0613 33.6567 55.4538 34.2984 54.2929 35.4358L54.2631 35.4649C53.1022 36.6315 52.4771 38.148 52.4771 39.7521C52.4771 41.3561 53.1022 42.8727 54.2631 44.0393C54.2631 44.0684 54.2929 44.0684 54.2929 44.0976C55.4836 45.2642 57.0315 45.8766 58.6985 45.8766C60.3655 45.8766 61.9134 45.235 63.1041 44.0976C64.265 42.931 64.9199 41.4145 64.9199 39.7813C64.9199 38.148 64.265 36.6315 63.1041 35.4649ZM61.5562 39.7813C61.5562 40.5395 61.2585 41.2395 60.7227 41.7644C60.1869 42.2894 59.4427 42.5811 58.6687 42.5811C57.8948 42.5811 57.1804 42.2894 56.6148 41.7644L56.585 41.7353C56.0789 41.2103 55.7813 40.5104 55.7813 39.7813C55.7813 39.0521 56.0789 38.3522 56.585 37.8273L56.6148 37.7981C57.1506 37.2731 57.8948 36.9814 58.6687 36.9814C59.4427 36.9814 60.1571 37.2731 60.7227 37.7981C61.2585 38.323 61.5562 39.023 61.5562 39.7813Z"
            fill="#1E3888"
        />
        <path
            d="M58.6688 43.281C60.6416 43.281 62.2409 41.7141 62.2409 39.7812C62.2409 37.8484 60.6416 36.2815 58.6688 36.2815C56.696 36.2815 55.0967 37.8484 55.0967 39.7812C55.0967 41.7141 56.696 43.281 58.6688 43.281Z"
            fill="#B0E6FB"
        />
        <path
            d="M44.798 29.2238H40.9878C40.6901 29.2238 40.4222 29.4571 40.4222 29.7779V35.1442C40.4222 37.7982 39.3506 40.3062 37.4157 42.2311C36.4631 43.1643 35.3319 43.9226 34.0816 44.4184C32.8314 44.9142 31.5217 45.1767 30.1821 45.1767V50.018C34.2305 50.018 38.011 48.4723 40.8985 45.6725C42.3273 44.2726 43.4287 42.6686 44.2026 40.8604C44.9766 39.0522 45.3636 37.1565 45.3636 35.2025V29.8362C45.3636 29.4571 45.0957 29.2238 44.798 29.2238Z"
            fill="#028090"
        />
        <path
            d="M30.1815 45.1475C27.4726 45.1475 24.9126 44.0976 22.9479 42.2019C21.9954 41.2686 21.2214 40.1604 20.7154 38.9355C20.2093 37.7106 19.9414 36.4273 19.9414 35.1149V29.7487C19.9414 29.4571 19.7033 29.1946 19.3758 29.1946H15.5656C15.2679 29.1946 15 29.4279 15 29.7487V35.1149C15 39.0813 16.5777 42.7852 19.4353 45.6141C20.8642 47.014 22.5014 48.0931 24.347 48.8514C26.1926 49.6097 28.1275 49.9888 30.122 49.9888L30.1815 45.1475Z"
            fill="#14C560"
        />
    </svg>
);

const HomeInsuranceIcon = ({ className, width = 24, height = 24 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_8368_36280)">
            <path
                d="M46.738 67H18.7253C16.5529 67 14.791 65.2381 14.791 63.0657V32.5748L46.738 13.0813V67Z"
                fill="#1BE7FF"
            />
            <path
                d="M26.6946 39.758L14.791 46.4146V63.0657C14.791 65.2381 16.5529 67 18.7253 67H38.5983V46.4146L26.6946 39.758V39.758Z"
                fill="#028090"
            />
            <path
                d="M52.6858 23.7225L38.5981 32.1294V67.0039H62.8352C65.0076 67.0039 66.7695 65.2419 66.7695 63.0695V32.1256L52.6819 23.7187L52.6858 23.7225Z"
                fill="#14C560"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.3723 37.4462H51.2495C51.5747 37.4462 51.838 37.7095 51.838 38.0348V40.9119C51.838 41.2372 51.5709 41.5005 51.2495 41.5005H48.3723C48.047 41.5005 47.7837 41.2333 47.7837 40.9119V38.0348C47.7837 37.7095 48.047 37.4462 48.3723 37.4462"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.1228 37.4462H56.9999C57.3252 37.4462 57.5885 37.7095 57.5885 38.0348V40.9119C57.5885 41.2372 57.3213 41.5005 56.9999 41.5005H54.1228C53.7975 41.5005 53.5342 41.2333 53.5342 40.9119V38.0348C53.5342 37.7095 53.7975 37.4462 54.1228 37.4462"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.3723 43.1811H51.2495C51.5747 43.1811 51.838 43.4445 51.838 43.7697V46.6469C51.838 46.9722 51.5709 47.2355 51.2495 47.2355H48.3723C48.047 47.2355 47.7837 46.9683 47.7837 46.6469V43.7697C47.7837 43.4445 48.047 43.1811 48.3723 43.1811"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.1228 43.1811H56.9999C57.3252 43.1811 57.5885 43.4445 57.5885 43.7697V46.6469C57.5885 46.9722 57.3213 47.2355 56.9999 47.2355H54.1228C53.7975 47.2355 53.5342 46.9683 53.5342 46.6469V43.7697C53.5342 43.4445 53.7975 43.1811 54.1228 43.1811"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5695 51.0769H25.6528C25.8891 51.0769 26.0788 51.2705 26.0788 51.5028V53.5862C26.0788 53.8224 25.8852 54.0121 25.6528 54.0121H23.5695C23.3333 54.0121 23.1436 53.8185 23.1436 53.5862V51.5028C23.1436 51.2666 23.3372 51.0769 23.5695 51.0769Z"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.736 51.0769H29.8193C30.0556 51.0769 30.2453 51.2705 30.2453 51.5028V53.5862C30.2453 53.8224 30.0517 54.0121 29.8193 54.0121H27.736C27.4998 54.0121 27.3101 53.8185 27.3101 53.5862V51.5028C27.3101 51.2666 27.5037 51.0769 27.736 51.0769Z"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5695 55.2319H25.6528C25.8891 55.2319 26.0788 55.4255 26.0788 55.6579V57.7451C26.0788 57.9813 25.8852 58.171 25.6528 58.171H23.5695C23.3333 58.171 23.1436 57.9774 23.1436 57.7451V55.6579C23.1436 55.4217 23.3372 55.2319 23.5695 55.2319Z"
                fill="#FFC53A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.736 55.2319H29.8193C30.0556 55.2319 30.2453 55.4255 30.2453 55.6579V57.7451C30.2453 57.9813 30.0517 58.171 29.8193 58.171H27.736C27.4998 58.171 27.3101 57.9774 27.3101 57.7451V55.6579C27.3101 55.4217 27.5037 55.2319 27.736 55.2319Z"
                fill="#FFC53A"
            />
            <path
                d="M46.4499 13.11L11.3766 33.9681C11.009 34.1868 10.8882 34.662 11.1068 35.0297L12.3023 37.0399C12.5209 37.4076 12.9962 37.5283 13.3638 37.3097L48.4372 16.4516C48.8048 16.2329 48.9256 15.7577 48.707 15.39L47.5115 13.3798C47.2928 13.0121 46.8176 12.8913 46.4499 13.11Z"
                fill="#FF8E72"
            />
            <path
                d="M41.2545 47.0109L27.1281 38.6079C27.0003 38.5304 26.8571 38.4995 26.7177 38.4995C26.7099 38.4995 26.7022 38.4995 26.6944 38.4995C26.6867 38.4995 26.6789 38.4995 26.6712 38.4995C26.5318 38.4995 26.3885 38.5304 26.2607 38.6079L12.1344 47.0109C11.7626 47.2316 11.6387 47.7157 11.8594 48.0874L13.0444 50.0778C13.2651 50.4496 13.7491 50.5735 14.1248 50.3527L26.6983 42.8752L39.2718 50.3527C39.6436 50.5735 40.1276 50.4534 40.3484 50.0778L41.5333 48.0874C41.754 47.7157 41.634 47.2316 41.2584 47.0109H41.2545Z"
                fill="#FF8E72"
            />
            <path
                d="M69.0971 33.3376L53.112 23.831C52.9881 23.7574 52.8526 23.7264 52.717 23.7225C52.7054 23.7225 52.6938 23.7225 52.686 23.7225C52.6744 23.7225 52.6628 23.7225 52.6551 23.7225C52.5195 23.7225 52.384 23.7574 52.2601 23.831L36.275 33.3376C35.9033 33.5583 35.7793 34.0424 36.0001 34.4141L37.185 36.4045C37.4057 36.7763 37.8898 36.9002 38.2615 36.6795L52.686 28.1022L67.1106 36.6795C67.4823 36.9002 67.9664 36.7801 68.1871 36.4045L69.372 34.4141C69.5927 34.0424 69.4727 33.5583 69.0971 33.3376V33.3376Z"
                fill="#FF8E72"
            />
        </g>
        <defs>
            <clipPath id="clip0_8368_36280">
                <rect width="58.4803" height="54" fill="white" transform="translate(11 13)" />
            </clipPath>
        </defs>
    </svg>
);

const MenuBtnIcon = ({ className, width = 32, height = 32, onClick }) => (
    <svg
        onClick={onClick}
        className={className}
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4840_4451)">
            <mask
                id="mask0_4840_4451"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="32"
                style={{ maskType: 'luminance' }}
            >
                <path d="M32 0H0V32H32V0Z" fill="white"></path>
            </mask>
            <g mask="url(#mask0_4840_4451)">
                <path
                    d="M9.76746 12.9717C9.76746 12.9727 22.2325 12.9717 22.2325 12.9717"
                    stroke="#006600"
                    strokeWidth="2.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M9.76746 19.2384C9.76746 19.2373 22.2325 19.2384 22.2325 19.2384"
                    stroke="#006600"
                    strokeWidth="2.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_4840_4451">
                <rect width="32" height="32" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);

const DownOutlinedIcon = ({ className, width = 20, height = 20 }) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 8L10 13L5 8"
            stroke="#373A40"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    </svg>
);

export {
    VietnamIcon,
    UserIcon,
    PlaneIcon,
    AccidentIcon,
    BikeIcon,
    CarComprehensiveIcon,
    CarIcon,
    HealthCancerIcon,
    HealthIcon,
    HomeInsuranceIcon,
    MenuBtnIcon,
    DownOutlinedIcon,
};
